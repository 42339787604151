.landingPageMainContainer {

    .section1 {
        // background-color: #F5F5F5;

background-image: url('../../assets/images/landingpageBg.webp');
background-size: auto 100%;
        .headText {
            font: 400 37px 'Urbanist';
            margin: 0 auto;
            width: 70%;
            text-align: center;
            color: #616161;
            padding-top:15px
        }
    }

    h1 {
        text-align: center;
        font: 800 45px 'Urbanist';
        margin: 0 auto;
        // width: 70%;
        text-align: center;
        color: #393939;
        padding-top: .3rem;
    }

    .buttonGroup {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 70px;
        align-content: center;

        button {
            // width: 140px;
            height: 57px;
            border-radius: 29.5px;
            background-color: #111111;
            color: #FFFFFF;
            font-weight: 300;
            font-size: 16px;
            border: none;
            margin: 10px;
            display: flex;
            align-items: center;
            font-family: 'Urbanist';
            padding: 0 26px;

            img {
                padding: 0 10px;
            }
        }
    }

    .mobileGroup {
        width: 58%;
        display: flex;
        margin: auto;
    }

    a {
        cursor: context-menu !important;
    }

    a > button {
        text-decoration: none;
        cursor: pointer !important;
    }

    .container {

        // width: 100%;
        .logo {
            width: unset;
            margin: auto;
            display: flex;
            padding-top: 2%;
        }

        .heading {
            font: 600 35px 'Urbanist';
            text-align: center;
            color: #000000;
            padding: 4% 10px 5px;
        }

        .description {
            font: 400 20px 'Urbanist';
            text-align: center;
            color: #3E3E3E;
            width: 57%;
            margin: auto;
            padding: 10px;
            line-height: 30px;

            .description_sub {
                display:block;
            }
        }

        .journeySteps {
            display: flex;
            flex-direction: row;
            // width: 100%;
            padding: 2% 10% 0%;

            .stepContainer {
                width: 33%;
                text-align: center;

                .textHeading {
                    font: 600 22px 'Urbanist';
                    line-height: 26.4px;
                    margin-top: 20px;

                }

                p {
                    font: 400 14px 'Urbanist';
                    line-height: 16.8px;
                    color: #3E3E3E;
                    width: 60%;
                    margin: auto;
                    padding: 20px 0 30px 0;
                    height: 17px;
                    align-items: center;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                }

                .AndroidAndApple {
                    padding: 10px 0 20px 0;
                    height: 35px;
                }

                .textDesc {
                    font: 400 14px 'Urbanist';
                    line-height: 20.8px;
                    color: #3E3E3E;
                    width: 85%;
                    margin: 5% auto;
                    height: 125px;
                }

                .numberCount {
                    width: 35px;
                    height: 35px;
                    border-radius: 25px;
                    border: 1px dashed #C9C9C9;
                    text-align: center;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    margin: 15px auto;
                    font: 400 18px 'Urbanist';
                }
            }
        }

        .RegisterButton {
            // width: 140px;
            height: 50px;
            border-radius: 29.5px;
            background-color: #111111;
            color: #FFFFFF;
            font-weight: 500;
            font-size: 18px;
            border: none;
            justify-content: center;
            display: flex;
            align-items: center;
            margin: 20px auto 0;
            font-family: 'Urbanist';
        }

        .goalsContainer {
            // display: flex;
            // flex-direction: row;
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            padding: 3% 1% 0% 1%;

            .stepContainer1 {
                width: 95%;
                background-color: #CCFCDE;
                margin: 10px;

                .cardDesc {
                    padding: 5% 6% 10%;

                    .textDesc {
                        font: 400 16px 'Urbanist';
                        line-height: 26.4px;
                        margin-top: 20px;
                        color: #393939;
                    }

                    .textHeading {
                        font: 600 22px 'Urbanist';
                        line-height: 26.4px;
                        margin-top: 20px;
                        display: flex;
                        align-items: center;

                        img {
                            padding-right: 15px;
                        }

                    }

                }

                .cardHeader {
                    background-color: #FFFFFF;
                    opacity: 60%;
                    align-items: center;
                    display: flex;
                    padding: 20px 26px;

                    .redCircle {
                        width: 12px;
                        height: 12px;
                        background-color: #FF1111;
                        border-radius: 25px;
                        margin: 3px;
                    }

                    .yellowCircle {
                        width: 12px;
                        height: 12px;
                        background-color: #FFE344;
                        border-radius: 25px;
                        margin: 3px;

                    }

                    .greenCircle {
                        width: 12px;
                        height: 12px;
                        background-color: #25B17F;
                        border-radius: 25px;
                        margin: 3px;

                    }
                }
            }

            .stepContainer2 {
                width: 95%;
                background-color: #FEF18E;
                margin: 10px;

                .cardDesc {
                    padding: 5% 6% 10%;

                    .textDesc {
                        font: 400 16px 'Urbanist';
                        line-height: 26.4px;
                        margin-top: 20px;
                        color: #393939;
                    }

                    .textHeading {
                        font: 600 22px 'Urbanist';
                        line-height: 26.4px;
                        margin-top: 20px;
                        display: flex;
                        align-items: center;

                        // white-space: nowrap;
                        img {
                            padding-right: 15px;
                        }

                    }

                }

                .cardHeader {
                    background-color: #FFFFFF;
                    opacity: 60%;
                    align-items: center;
                    display: flex;
                    padding: 20px 26px;

                    .redCircle {
                        width: 12px;
                        height: 12px;
                        background-color: #FF1111;
                        border-radius: 25px;
                        margin: 3px;
                    }

                    .yellowCircle {
                        width: 12px;
                        height: 12px;
                        background-color: #FFE344;
                        border-radius: 25px;
                        margin: 3px;

                    }

                    .greenCircle {
                        width: 12px;
                        height: 12px;
                        background-color: #25B17F;
                        border-radius: 25px;
                        margin: 3px;

                    }
                }
            }

            .stepContainer3 {
                width: 95%;
                background-color: #C9ECFF;
                margin: 10px;

                .cardDesc {
                    padding: 5% 6% 10%;

                    .textDesc {
                        font: 400 16px 'Urbanist';
                        line-height: 26.4px;
                        margin-top: 20px;
                        color: #393939;
                    }

                    .textHeading {
                        font: 600 22px 'Urbanist';
                        line-height: 26.4px;
                        margin-top: 20px;
                        display: flex;
                        align-items: center;

                        img {
                            padding-right: 15px;
                        }

                    }

                }

                .cardHeader {
                    background-color: #FFFFFF;
                    opacity: 60%;
                    align-items: center;
                    display: flex;
                    padding: 20px 26px;

                    .redCircle {
                        width: 12px;
                        height: 12px;
                        background-color: #FF1111;
                        border-radius: 25px;
                        margin: 3px;
                    }

                    .yellowCircle {
                        width: 12px;
                        height: 12px;
                        background-color: #FFE344;
                        border-radius: 25px;
                        margin: 3px;

                    }

                    .greenCircle {
                        width: 12px;
                        height: 12px;
                        background-color: #25B17F;
                        border-radius: 25px;
                        margin: 3px;

                    }
                }
            }

            .stepContainer4 {
                width: 95%;
                background-color: #F1F1F1;
                margin: 10px;

                .cardDesc {
                    padding: 5% 6% 10%;

                    .textDesc {
                        font: 400 16px 'Urbanist';
                        line-height: 26.4px;
                        margin-top: 20px;
                        color: #393939;
                    }

                    .textHeading {
                        font: 600 22px 'Urbanist';
                        line-height: 26.4px;
                        margin-top: 20px;
                        display: flex;
                        align-items: center;

                        img {
                            padding-right: 15px;
                        }

                    }

                }

                .cardHeader {
                    background-color: #FFFFFF;
                    opacity: 60%;
                    align-items: center;
                    display: flex;
                    padding: 20px 26px;

                    .redCircle {
                        width: 12px;
                        height: 12px;
                        background-color: #FF1111;
                        border-radius: 25px;
                        margin: 3px;
                    }

                    .yellowCircle {
                        width: 12px;
                        height: 12px;
                        background-color: #FFE344;
                        border-radius: 25px;
                        margin: 3px;

                    }

                    .greenCircle {
                        width: 12px;
                        height: 12px;
                        background-color: #25B17F;
                        border-radius: 25px;
                        margin: 3px;

                    }
                }
            }
        }

        .gridContainer {
            display: flex;
            flex-direction: row;
            background-color: #FAFAFA;
            margin: 3% 5%;

            .ContentSection {
                width: 50%;
                padding: 7% 4%;

                .Head {
                    font: 400 38px 'Urbanist';
                    width: 80%;
                }

                span {
                    font: 700 38px 'Urbanist';

                }

                .desc {
                    font: 400 20px 'Urbanist';
                    color: #757575;
                    margin-top: 15px;
                    width: 90%;
                }
            }

            .imageSection {
                width: 50%;

                .learningWeb {
                    display: flex;
                    margin: 2% 0;
                    width: 585px;
                    height: 451px;
                    // margin-top: 2%;
                }
            }
        }


        .yourPlanContainer {
            display: flex;
            flex-direction: row;
            padding: 2% 20% 1%;

            .individuals {
                width: 70%;
                background-color: #C9ECFF;
                margin: 0 1%;
                padding: 20px 40px;
                border-radius: 15px;
                justify-content: center;
                display: block;
                align-content: center;
                justify-content: center;

                button {
                    background-color: #FFFFFF;
                    color: #1E1E1E;
                    font: 500 20px 'Urbanist';
                    width: 400px;
                    border-radius: 10px;
                    display: block;
                    margin: auto;
                    padding: 15px 0;
                }

                .customList {
                    list-style-type: none;
                    padding: 0;
                    height: 185px;
                }

                .freeIcon {
                    width: 180px;
                    margin-top: 70px;
                }

            }

            .team {
                width: 30%;
                background-color: #FEF18E;
                margin: 0 1%;
                padding: 20px 40px;
                border-radius: 15px;
                justify-content: center;
                display: block;
                align-content: center;
                justify-content: center;

                .comingSoon {
                    padding: 0px 12px;
                }

                button {
                    background-color: #FFFFFF;
                    color: #1E1E1E;
                    font: 500 20px 'Urbanist';
                    width: 265px;
                    border-radius: 10px;
                    display: block;
                    margin: auto;
                    padding: 15px 0;
                }

                .customList {
                    list-style-type: none;
                    padding: 0;
                    height: 175px;
                }

                .proIcon {
                    width: 140px;
                }
            }

            .Heading {
                font: 700 40px 'Urbanist';
                line-height: 48px;
                text-align: center;
                color: #000000;
            }

            .Desc {
                font: 400 22px 'Urbanist';
                // line-height: 48px;
                text-align: center;
                color: #333333;
            }

            img {
                // width: 80%;
                padding: 25px;
                margin: auto;
                display: block;
            }

            .customList li::before {
                content: url('../../assets/images/tick.svg');
                margin-right: 15px;
            }

            li {
                padding: 10px 20px;
                font: 400 20px 'Urbanist';
            }


        }

        .freeDemo {
            background-color: #F5F5F5;
            margin: 0% 20.5% 4%;
            padding: 2% 4%;
            border-radius: 15px;
            font: 600 35px 'Urbanist';
            color: #000000;

            span {
                font: 400 23px 'Urbanist';
                color: #989898;
            }

            button {
                width: 140px;
                height: 50px;
                border-radius: 29.5px;
                background-color: #111111;
                color: #FFFFFF;
                font-weight: 500;
                font-size: 18px;
                border: none;
                float: right;
                font-family: 'Urbanist';
            }

        }

        .makeUsDifferentMainContainer {
            display: flex;
            flex-direction: row;
            padding: 3% 2% 0;

            .leftsideContainer {
                width: 40%;

                .cardContainer {
                    display: flex;
                    padding: 15% 0;
                }

                .Card {
                    background-color: #F9F9F9;
                    padding: 4% 6%;
                    width: 56%;
                    border-radius: 15px;
                    min-height: 120px;
                    display:flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position:relative;

                    .card_pic{
                        position:absolute;
                        top: 0;
                        left:0;
                    }

                    .headingTxt {
                        text-align: center;
                        font: 600 20px 'Urbanist';
                        color: #000000;
                        padding: 10px 0;
                    }

                    .desc {
                        text-align: center;
                        font: 600 20px 'Urbanist';
                        color: #313131;
                        padding: 10px 0;
                        line-height: 24px;
                    }
                }

                .circleContainer {
                    display: flex;
                    align-items: center;
                    padding: 0 15px;

                    .line {
                        width: 108px;
                        height: 0px;
                        left: 40px;
                        border: 1px dashed #525252;
                    }

                    .CircleGreen {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        position: relative;
                        border: 2px solid #22D163;

                        .dot {
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: #22D163;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    .CircleRed {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        position: relative;
                        border: 2px solid #FF1748;

                        .dot {
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: #FF1748;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }


            }

            .centerImageContainer {
                width: 25%;
                margin: auto;
                text-align: center;
            }

            .rightsideContainer {
                width: 40%;


                .cardContainer {
                    display: flex;
                    padding: 15% 0;
                }

                .Card {
                    background-color: #F9F9F9;
                    padding: 4% 6%;
                    width: 56%;
                    border-radius: 15px;
                    min-height: 120px;
                    display:flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position:relative;

                    .card_pic{
                        position:absolute;
                        top: 0;
                        left:0;
                    }


                    .headingTxt {
                        text-align: center;
                        font: 600 20px 'Urbanist';
                        color: #000000;
                        padding: 10px 0;
                    }

                    .desc {
                        text-align: center;
                        font: 600 20px 'Urbanist';
                        color: #313131;
                        padding: 10px 0;
                        line-height: 24px;
                    }
                }

                .circleContainer {
                    display: flex;
                    align-items: center;
                    padding: 0 15px;

                    .line {
                        width: 108px;
                        height: 0px;
                        left: 40px;
                        border: 1px dashed #525252;
                    }

                    .CircleGreen {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        position: relative;
                        border: 2px solid #22D163;

                        .dot {
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: #22D163;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    .CircleYellow {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        position: relative;
                        border: 2px solid #FFD600;

                        .dot {
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: #FFD600;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    .CircleBlue {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        position: relative;
                        border: 2px solid #20A1E8;

                        .dot {
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: #20A1E8;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }


    }

    .ourAppContainer {
        background-color: #E9F7FF;
        margin: 5%;
        padding: 4% 4% 0;
        display: flex;
        flex-direction: row;
        border-radius: 15px;

        .contentContainer {
            width: 65%;

            .heading {
                font: 600 40px 'Urbanist';
                text-align: left;
                color: #000000;
                padding: 4% 10px 5px;
                width: 60%;
            }

            .description {
                font: 400 20px 'Urbanist';
                text-align: left;
                color: #3E3E3E;
                width: 70%;
                // margin: auto;
                padding: 10px;
                line-height: 30px;
            }

            .downloadButton {
                background-color: #111111;
                color: #FFFFFF;
                font: 400 14px 'Urbanist';
                border-radius: 25px;
                margin: 10px;
            }

            .buttonGroup {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: left;
                margin-top: 25px;
                align-content: center;

                button {
                    // width: 140px;
                    height: 50px;
                    border-radius: 29.5px;
                    background-color: #111111;
                    color: #FFFFFF;
                    font-weight: 500;
                    font-size: 18px;
                    border: none;
                    margin: 10px;
                    display: flex;
                    align-items: center;
                    font-family: 'Urbanist';

                    img {
                        padding: 0 10px;
                    }
                }
            }
        }

    }



}



.collapseContainer {
    margin: auto;
    display: flex;
}

.icon {
    margin-left: 5px;
    float: right;
}

.collapsible {
    background-color: #F5F5F5;
    color: #333;
    cursor: pointer;
    padding: 20px 20px;
    border: none;
    outline: none;
    width: 65%;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    margin: 10px auto;
    font: 500 18px 'Urbanist';
    border-radius: 10px;
    font-weight: 600;
}

.collapsed {
    display: none;
}

.content {
    transition: max-height 0.3s ease-out;
    max-height: 500px;
    overflow: hidden;
    width: 100%;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    font: 400 15px 'Urbanist';
    border-radius: 10px;
    color: #4F4F4F;

    p {
        padding: 10px 20px 10px 0;
        text-align: left;
    }
}


//testing 



.testimonial-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.testimonial {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.image {
    margin-bottom: 10px;
}

.image.large {
    width: 200px;
    /* Adjust width of the large images */
}

.image.small {
    width: 100px;
    /* Adjust width of the small images */
}

.content {
    text-align: center;
}

.icons {
    position: absolute;
    bottom: 10px;
}

.icons button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    font-family: 'Urbanist';
}


@media screen and (min-width:400px) and (max-width: 900px) {
    .landingPageMainContainer {
        h1 {
            font-size: 26px;
            width: 75%;
        }

        .buttonGroup {
            margin-top: 25px;

            button {
                font-size: 10px;
                height: 40px;
                padding: 10px 15px;

                img {
                    width: 15px;
                    padding: 5px;
                    height: 15px;
                }
            }
        }

        .section1 {
            padding-top: 20px;

            .headText {
                font-size: 20px;

            }

            .mobileGroup {
                width: 80%;
            }
        }

        .container {
            .logo {
                width: unset;
            }

            .heading {
                font-size: 26px;
                padding: 8% 10px 5px;
            }

            .description {
                width: 70%;
                font-size: 15px;
            }

            .description_sub{
                display:inline;
            }

            .journeySteps {
                flex-direction: column;

                .stepContainer {
                    width: 100%;
                    margin-bottom: 20px;
                    .textDesc{
                        height: unset;
                    }
                }
            }

            .makeUsDifferentMainContainer {
                flex-direction: column;

                .leftsideContainer,
                .rightsideContainer {
                    width: 100%;

                    .cardContainer {
                        padding: 4% 0;

                        .Card {
                            width: 100%;
                        }

                        .circleContainer {
                            display: none;
                        }
                    }
                }

                .centerImageContainer {
                    width: 100%;
                    text-align: center;
                }
            }

            .goalsContainer {
                display: grid;
                grid-template-columns: repeat(1, 1fr);

                .stepContainer1,
                .stepContainer2,
                .stepContainer3 {
                    width: 95%;
                }
            }

            .gridContainer {
                flex-direction: column;

                .ContentSection {
                    width: 90%;
                    .Head {
                        font: 400 24px 'Urbanist';
                    }
                    span {
                        font: 700 24px 'Urbanist';
    
                    }
    
                    .desc {
                        font: 400 14px 'Urbanist';
                    }
                }

                .imageSection {
                    width: 90%;
                    margin: auto;

                    .learningWeb {
                        width: 100%;
                        height: unset;
                    }
                }
            }

            .yourPlanContainer {
                flex-direction: column;
                padding: 4% 4% 1%;

                .individuals {
                    width: 75%;
                    margin: 4% auto;

                    button {
                        width: 240px;
                    }
                }

                .team {
                    width: 75%;
                    margin: 4% auto;

                    button {
                        width: 240px;
                    }
                }

            }

            .freeDemo {
                font: 600 18px 'Urbanist';
                margin: 0% 4% 4%;

                span {
                    font: 400 10px 'Urbanist';
                }

                button {
                    width: 90px;
                    height: 32px;
                    font-size: 14px;
                    text-align: center;
                    align-items: center;
                    display: flex;
                    margin-top: -5px;
                }

            }

            .collapseContainer {
                .collapsible {
                    width: 80%;
                }
            }

            .testimonial-carousel {
                button {
                    top: 90%;

                }

                .prev {
                    left: 10%;
                }

                .next {
                    right: 10%;
                }

                .testimonialName {
                    font: 600 20px 'Urbanist';
                }

                .testimonialText {

                    font: 500 13px 'Urbanist';

                }

                .star-rating {
                    font-size: 15px;
                }

                .testimonial-images img {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    margin: 30px 30px;
                    border: 2px solid transparent;
                }

                .testimonial-images img.highlight {
                    // border-color: #f00;
                    transform: scale(1.6);
                }
            }

        }

        .ourAppContainer {
            flex-direction: column;

            // width: 100%;
            .contentContainer {
                width: 100%;

                .heading {
                    width: 95%;
                    font-size: 35px;
                }

                .description {
                    width: 80%;
                    font-size: 18px;
                }
            }

            .imageContainer {
                margin: 8% auto;
            }

        }
    }

}


@media screen and (max-width: 400px) {
    .landingPageMainContainer {
        h1 {
            font-size: 26px;
            width: 75%;
        }

        .buttonGroup {
            margin-top: 25px;

            button {
                font-size: 10px;
                height: 40px;
                padding: 10px 15px;

                img {
                    width: 15px;
                    height: 15px;
                    padding: 5px;
                }
            }
        }

        .section1 {
            padding-top: 20px;

            .headText {
                font-size: 20px;

            }

            .mobileGroup {
                width: 80%;
            }
        }

        .container {
            .logo {
                width: unset;
            }

            .heading {
                font-size: 26px;
                padding: 8% 10px 5px;
            }

            .description {
                width: 70%;
                font-size: 15px;
            }

            .description_sub{
                display:inline;
            }

            .journeySteps {
                flex-direction: column;

                .stepContainer {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }

            .makeUsDifferentMainContainer {
                flex-direction: column;

                .leftsideContainer,
                .rightsideContainer {
                    width: 100%;

                    .cardContainer {
                        padding: 4% 0;

                        .Card {
                            width: 100%;
                        }

                        .circleContainer {
                            display: none;
                        }
                    }
                }

                .centerImageContainer {
                    width: 100%;
                    text-align: center;
                }
            }

            .goalsContainer {
                display: grid;
                grid-template-columns: repeat(1, 1fr);

                .stepContainer1,
                .stepContainer2,
                .stepContainer3 {
                    width: 95%;
                }
            }

            .gridContainer {
                flex-direction: column;

                .ContentSection {
                    width: 90%;
                    .Head {
                        font: 400 24px 'Urbanist';
                    }
                    span {
                        font: 700 24px 'Urbanist';
    
                    }
    
                    .desc {
                        font: 400 14px 'Urbanist';
                    }
                }

                .imageSection {
                    width: 90%;
                    margin: auto;

                    .learningWeb {
                        width: 100%;
                        height: unset;
                    }
                }
            }

            .yourPlanContainer {
                flex-direction: column;
                padding: 4% 4% 1%;

                .individuals {
                    width: 75%;
                    margin: 4% auto;

                    button {
                        width: 240px;
                    }
                    .freeIcon{
                        width: 140px;
                        margin-top: 20px;
                    }
                }

                .team {
                    width: 75%;
                    margin: 4% auto;

                    button {
                        width: 240px;
                    }
                    .proIcon{
                        width: 120px;
                    }
                    .comingSoon{
                        width: 100%;
                    }
                }

            }

            .freeDemo {
                font: 600 18px 'Urbanist';
                margin: 0% 4% 4%;

                span {
                    font: 400 10px 'Urbanist';
                }

                button {
                    width: 90px;
                    height: 32px;
                    font-size: 14px;
                    text-align: center;
                    align-items: center;
                    display: flex;
                    margin-top: -5px;
                }

            }

            .collapseContainer {
                .collapsible {
                    width: 80%;
                }
            }

            .testimonial-carousel {
                button {
                    top: 90%;

                }

                .prev {
                    left: 10%;
                }

                .next {
                    right: 10%;
                }

                .testimonialName {
                    font: 600 20px 'Urbanist';
                }

                .testimonialText {

                    font: 500 13px 'Urbanist';

                }

                .star-rating {
                    font-size: 15px;
                }

                .testimonial-images img {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    margin: 30px 30px;
                    border: 2px solid transparent;
                }

                .testimonial-images img.highlight {
                    // border-color: #f00;
                    transform: scale(1.6);
                }
            }

        }

        .ourAppContainer {
            flex-direction: column;

            // width: 100%;
            .contentContainer {
                width: 100%;

                .heading {
                    width: 95%;
                    font-size: 35px;
                }

                .description {
                    width: 80%;
                    font-size: 18px;
                }
                .buttonGroup{
                    button{
                        font-size: 14px;
                        white-space: nowrap;
                    }
                }
            }

            .imageContainer {
                margin: 8% auto;
                img{
                    width: 100%;
                }
            }

        }
    }

}


/* TestimonialCarousel.css */
.testimonial-carousel {
    position: relative;
    margin-top: 2%;

    .star-rating {
        font-size: 25px;
        text-align: center;
    }

    .star-rating span {
        cursor: pointer;
    }

    .star-rating .star-filled {
        color: gold;
        text-align: center;
    }

    .star-rating .star-empty {
        color: #ccc;
    }

    .testimonial-images {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .testimonial-images img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin: 40px 40px;
        border: 2px solid transparent;
    }

    .testimonial-images img.highlight {
        // border-color: #f00;
        transform: scale(1.6);
    }

    .testimonialName {
        text-align: center;
        font: 600 30px 'Urbanist';
        color: #090914;
    }

    .testimonialText {
        // margin-bottom: 20px;
        text-align: center;
        margin: 4% auto;
        font: 500 20px 'Urbanist';
        color: #4F4F4F;
        width: 50%;
    }

    button {
        background: transparent;
        border: none;
        font-size: 24px;
        // cursor: pointer;
        position: absolute;
        top: 93%;
        transform: translateY(-50%);
        border-radius: 25px;
        background-color: #F5F5F5;
    }

    .prev {
        left: 17%;
    }

    .next {
        right: 17%;
    }

}




@media screen and (min-width: 900px) and (max-width: 1024px) {
    .landingPageMainContainer {
        .section1 {
            h1 {
                font: 600 28px 'Urbanist'
            }

            .headText {
                font: 600 24px 'Urbanist';
            }
        }

        .container {
            // width: 100%;

            .heading {
                font: 600 30px 'Urbanist';
                padding: 8% 10px 5px;
            }

            .description {
                width: 75%;
                font: 400 18px 'Urbanist';

            }

            .journeySteps {
                padding: 2% 4% 0%;

                .stepContainer {
                    .textHeading {
                        font: 600 18px 'Urbanist';
                    }

                    .textDesc {
                        // font: 400 13px 'Urbanist'
                        height: 145px;
                    }
                }

            }

            .makeUsDifferentMainContainer {

                .leftsideContainer,
                .rightsideContainer {
                    .cardContainer {
                        padding: 25% 0;
                    }

                    .circleContainer {
                        .line {
                            width: 60px;
                        }
                    }
                }

                .centerImageContainer {
                    width: unset;
                }
            }

            .goalsContainer {

                .stepContainer1,
                .stepContainer2,
                .stepContainer3,
                .stepContainer4 {
                    .cardDesc {
                        .textHeading {
                            font: 600 20px 'Urbanist';
                            line-height: 26.4px;
                        }

                        .textDesc {
                            font: 400 16px 'Urbanist';
                            line-height: 26.4px;
                        }
                    }
                }
            }

            .gridContainer {
                .ContentSection {
                    .Head {
                        font: 400 24px 'Urbanist';
                    }

                    span {
                        font: 700 24px 'Urbanist';

                    }

                    .desc {
                        font: 400 14px 'Urbanist';
                    }

                }

                .imageSection {
                    .learningWeb {
                        width: 342px;
                        height: unset;
                    }
                }

            }

            .yourPlanContainer {
                padding: 2% 6% 1%;

                .individuals {
                    width: 60%;

                    button {
                        padding: 15px 30px;
                        width: max-content;
                    }

                    .freeIcon {
                        width: 150px;
                        margin-top: 55px;
                    }
                }

                .team {
                    width: 40%;

                    .comingSoon {
                        width: 165px;
                    }

                    .proIcon {
                        width: 120px;
                    }
                }
            }

            .collapseContainer {
                .collapsible {
                    width: 70%;
                }
            }

            .RegisterButton {
                font-size: 16px;
            }

        }

        .ourAppContainer {
            .contentContainer {
                width: 70%;

                .heading {
                    width: 80%;
                    font: 600 30px 'Urbanist';
                }

                .description {
                    font: 400 16px 'Urbanist';
                }

                .buttonGroup {
                    margin-top: 12px;

                    button {
                        font-size: 14px;
                        height: 45px;

                        img {
                            width: 16px;
                        }
                    }
                }
            }

            .imageContainer {
                width: 30%;

                img {
                    width: 100%;
                }
            }
        }
    }


}

.privacy-policy{
    padding: 3% 5.6%;
}

@media (max-width: 600px ){
    .privacy-policy{
        padding: 0% 6%;
    }
}