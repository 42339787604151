body{
  margin: 0;
  padding:0
}

.container{
  padding: 16px 3rem;
}

@media (max-width: 600px) {
  .container{
    padding: 16px 2rem;
  }
}

.redirect-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  font-family: 'Urbanist';
}