.layoutMainContainer {
    // padding: 3% 6%;
    // background-color: #F5F5F5 ;
}

.headerComponent {
    height: 10vh;
    background-color: #F5F5F5;
    padding: 3% 6%;

    img {
        width: 18.5%;
        // height: 15%;
    }

    .registerButton {
        // width: 140px;
        height: 50px;
        border-radius: 29.5px;
        background-color: #111111;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 18px;
        border: none;
    }

    button {
        float: inline-end;
    }
}

.window_design{
    position: absolute; 
    bottom:-17%;
    left:-2%;
    z-index:-1;
}
.window_designr{
    position: absolute; 
    bottom:-17%;
    right:-2%;
    z-index:-1;
}


.footerContainer {
    background-color: #F5F5F5;
    padding-bottom: 2%;
    margin-top: 4%;

    .Footer {
        display: flex;
        flex-direction: row;
        padding: 3% 5%;

        .FooterText {
            font: 600 25px 'Urbanist';
            color: #000000
        }

        .leftArea {
            width: 50%;
            text-align: left;

            p {
                margin: 5px;
                color: #838383;
                font: 400 18px 'Urbanist';
            }

            .buttonGroup {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: left;
                margin-top: 15px;
                align-content: center;
                margin-bottom: 7%;

                button {
                    // width: 140px;
                    // height: 50px;
                    border-radius: 29.5px;
                    background-color: #111111;
                    color: #FFFFFF;
                    font-weight: 100;
                    font-size: 16px;
                    border: none;
                    margin: 10px 10px 10px 0px;
                    display: flex;
                    align-items: center;
                    padding: 18px 25px;

                    img {
                        padding: 0 10px;
                    }
                }
            }

            .contactGroup {
                display: flex;
                padding-top: 4%;

                img {
                    padding: 0 10px 0 0;
                }
            }

            .input-container {
                position: relative;
                width: 75%;
                margin-top: 15px;

            }

            .input-field {
                padding-right: 2.5rem;
                width: 100%;
                box-sizing: border-box;
                background-color: #FFFFFF;
                color: #000000;
                border: none;
                height: 55px;
            }

            .suffix-icon {
                position: absolute;
                top: 45%;
                right: 0;
                transform: translateY(-50%);
            }



        }

        .rightArea {
            width: 50%;
            text-align: right;

            .contactGroup {
                display: flex;
                text-align: end;
                align-items: flex-end;
                float: right;
                margin-top: 4%;

                p {
                    padding: 0 20px;
                    font: 400 18px 'Urbanist';
                    color: #000000;

                    span {
                        color: #F5F5F5;
                    }
                }
            }
        }
    }

    .copyRightsText {
        background-color: #0D0D0D;
        color: #FFFFFF;
        display: flex;
        font: 400 18px 'Urbanist';
        margin: 0% 5% 0% 5%;
        border-radius: 15px;
        flex-direction: row;
        padding:10px 0;
        justify-content: space-between;

        .list {
            display: flex;
            float: right;
            padding-right: 1rem;

            p {
                padding: 10px;
                width: auto;
                white-space: nowrap;
            }
        }

        p {
            padding: 10px 10px 10px 100px;
            width: 53%;
            margin: 4px;
        }
    }

    .profile-testimonial {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .testimonial-images {
        display: flex;
        justify-content: center;
    }

    .testimonial-images img {
        width: 100px;
        /* Adjust the width as needed */
        margin: 0 10px;
        /* Adjust the spacing between images as needed */
        cursor: pointer;
    }

    .testimonial-images img.selected {
        border: 2px solid blue;
        /* Add styling for selected image */
    }

    .testimonial-content {
        text-align: center;
        margin-top: 20px;
        /* Adjust the margin as needed */
    }
}


@media screen and (max-width: 768px) and (max-width: 900px)  {

    .headerComponent {
        img {
            width: 35%;
        }

        .registerButton {
            // width: 85px;
            height: 32px;
            font-size: 14px;
            text-align: center;
            align-items: center;
            display: flex;
        }
    }

    .footerContainer {
        .Footer {
            flex-direction: column;

            .leftArea {
                width: 100%;

                .input-container {
                    width: 85%;
                }

                .contactGroup {
                    padding-top: 4%;
                }
                .buttonGroup{
                    button{
                        font-size: 14px;
                    }
                }
            }

            .rightArea {
                width: 100%;

                .FooterText {
                    margin-top: 5%;
                }

                .buttonGroup {
                    margin-bottom: 3%;
                }

                .contactGroup {
                    white-space: nowrap;
                    float: left;
                    p{
                        font-size: 18px;
                    }
                }
            }
        }

        .copyRightsText {
            flex-direction: column;

            p {
                padding: 12px;
                width: 85%;
            }

            .list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

}
    @media screen and (min-width: 430px) and (max-width: 767px) {

    .headerComponent {
        img {
            width: 35%;
        }

        .registerButton {
            // width: 85px;
            height: 32px;
            font-size: 14px;
            text-align: center;
            align-items: center;
            display: flex;
        }
    }

    .footerContainer {
        .Footer {
            flex-direction: column;

            .leftArea {
                width: 100%;

                .input-container {
                    width: 85%;
                }

                .contactGroup {
                    padding-top: 4%;
                }
                .buttonGroup{
                    button{
                        font-size: 14px;
                    }
                }
            }

            .rightArea {
                width: 100%;

                .FooterText {
                    margin-top: 5%;
                }

                .buttonGroup {
                    margin-bottom: 3%;
                }

                .contactGroup {
                    white-space: nowrap;
                    float: left;
                    p{
                        font-size: 18px;
                    }
                }
            }
        }

        .copyRightsText {
            flex-direction: column;

            p {
                padding: 12px;
                width: 85%;
            }

            .list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

}

a {
    text-decoration: none;
    color: unset;
    cursor: context-menu !important;
}

.contactGroup > a{
    cursor: pointer !important;
}

.list > p > a {
    cursor: pointer !important;
}

a > button {
    cursor: pointer !important;
}


@media screen and (min-width: 1900px) {

    .layoutMainContainer .container,
    .ourAppContainer {
        padding: 0 10%;
    }

    .headerComponent {
        padding: 3% 16%;
    }

    .section1 {
        padding: 0 10%;
    }

    .footerContainer {
        padding: 0 10%;
    }

}

@media screen and (max-width: 430px){
    .footerContainer {
        .Footer {
            flex-direction: column;

            .leftArea {
                width: 100%;

                .input-container {
                    width: 85%;
                }

                .contactGroup {
                    padding-top: 4%;
                }
                .buttonGroup{
                    button{
                        font-size: 14px;
                    }
                }
            }

            .rightArea {
                width: 100%;

                .FooterText {
                    margin-top: 5%;
                }

                .buttonGroup {
                    margin-bottom: 3%;
                }

                .contactGroup {
                    white-space: nowrap;
                    float: left;
                    p{
                        font-size: 14px;
                    }
                }
            }
        }

        .copyRightsText {
            flex-direction: column;

            p {
                padding: 12px;
                width: 85%;
            }

            .list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
 }